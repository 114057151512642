﻿export interface EarningBand {
	title: string
	value: string
	qualifies: boolean
}

export interface CalculatorConfig {
	maximumSubscriptions: number
	earningsBands: EarningBand[]
}

const calculatorConfig: CalculatorConfig = {
	maximumSubscriptions: 10,
	earningsBands: [
		{
			title: "Less than £12,500 all years",
			value: "LT-12K-All",
			qualifies: false,
		},
		{
			title: "Less than £12,500 some years",
			value: "LT-12K-Some",
			qualifies: false,
		},
		{
			title: "Between £12,500 and £50,000 all years",
			value: "12K-50K-All",
			qualifies: true,
		},
		{
			title: "More than £50K some years",
			value: "MT-50K-Some",
			qualifies: true,
		},
		{
			title: "More than £50K all years",
			value: "MT-50K-All",
			qualifies: true,
		},
	],
}

export default calculatorConfig
