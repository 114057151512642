import { saveClaimToServer, saveMODClaimtoServer } from "@/apis"
import { useLoading } from "@/composition/useLoading"
import ClaimSteps, { ClaimStepData, ClaimType } from "@/steps"
import { ClaimStatus } from "@/types"
import { calculateTotalMonthsEmployed } from "@/utils/jobDates"
import { getNextStep } from "./getStep"
import runQualifyingChecks from "./qualifyingChecks"
import { SetClaimSetOptions, setClaimStep } from "./setStep"

const { runAction } = useLoading()

export default async function runPostSaveActions(requestedNextStep?: ClaimStepData, options?: SetClaimSetOptions) {
	//ClaimStage = ClaimStage + 1;
	// Increment claim stage if required and claim is not at an error/end point (arg passed from button click function)

	// Skip stages logic 2 - saving claim
	// If no subscriptions are available don't show subscriptions screens

	App.InitialLoadWithExistingClaim = false

	if (!requestedNextStep) {
		const next = getNextStep(CurrentStep)
		if (!next) {
			throw new Error("No next step")
		}
		requestedNextStep = next
	}

	//save MOD claim if needed
	if (CurrentStep.type === ClaimType.MoD && App.MODClaim.CustomerId > 0 && App.MODClaim.MobileNumber.length > 0) {
		await runAction(saveMODClaimtoServer(App.MODClaim))
		localStorage.setItem("MODClaim", JSON.stringify(App.MODClaim))
	}

	if (requestedNextStep === ClaimSteps.FinalStep) {
		if (testMode) console.log("Claim complete!! Going to do some stuff now...")

		if (App.CustomerClaim.EmploymentRecords.some((er) => er.PAYEReference === null) && !App.CustomerClaim.ConsentContactEmployers) {
			App.CustomerClaim.ClaimStatus = ClaimStatus.NotProvidedPAYEOrSARPermission
			App.CustomerClaim = await runAction(saveClaimToServer(App.CustomerClaim))
			await setClaimStep(ClaimSteps.EmployerDetailsNeeded)
			return
		}
		if (calculateTotalMonthsEmployed(App.CustomerClaim.EmploymentRecords) < 12) {
			App.CustomerClaim.ClaimStatus = ClaimStatus.SubmitNextTaxYear
			App.CustomerClaim = await runAction(saveClaimToServer(App.CustomerClaim))
			await setClaimStep(ClaimSteps.WaitUntilNextTaxYear)
			return
		}

		App.CustomerClaim.ClaimStatus = (App.CustomerClaim.EmploymentRecords.some((er) => er.PAYEReference === null) && App.CustomerClaim.ConsentContactEmployers) ? ClaimStatus.NotCompletedEmployerDetails : ClaimStatus.CompletePaymentSuccessful
		App.CustomerClaim = await runAction(saveClaimToServer(App.CustomerClaim))

		if (App.CustomerClaim.ClaimStatus === ClaimStatus.CancelledDetailsRejected) {
			await setClaimStep(ClaimSteps.DetailsRejected)
			return
		}

		if (!App.CustomerClaim.IsRepeatCustomer) {
			window.location.href = `${conversionUrl}/?cid=${App.CustomerClaim.ClaimId}` //'/payments';
		} else if (App.CustomerClaim.selfAssessed === false && App.CustomerClaim.ClaimStatus < 20) {
			// Repeat customer
			if (testMode) console.log("Repeat customer - not going to do stuff after all...")
			if (testMode) console.log("Redirecting to final page...")
			window.location.href = `${repeatCustomerConversionUrl}/${App.CustomerClaim.UserId}`
			return
		}
	}

	const successful = await runQualifyingChecks()
	if (!successful) {
		return
	}
	await setClaimStep(requestedNextStep, options)
}
