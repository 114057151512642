import ButtonNavigation from "@/components/ButtonNavigation"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import UniformBenefits from "./components/UniformBenefits"

const UniformClaimed: ReactiveComponent = () => {
	return () => (
		<>
			<div class="col-12 mb-1 text-center">
				<h3 class="bold">Your Claim</h3>
			</div>
			<div class="col-12 mb-1">
				<p>I'm sorry but You have indicated that you have claimed a uniform allowance.</p>
				<UniformBenefits />
			</div>
			<ButtonNavigation nextDisabled />
		</>
	)
}

export default defineComponent(UniformClaimed)
