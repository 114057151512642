import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import CurrencyInput from "@/components/CurrencyInput"
import type { Subscription } from "@/types"

interface Props {
	subscription: Subscription
}

const EditSubscription: ReactiveComponent<Props> = (props) => {
	return () => (
		<div class="Subscription py-3" style="border-bottom:1px solid #fff;" data-subscriptionid={props.subscription.OccupationSubscriptionId}>
			<div class="py-1">
				<div>{props.subscription.Name}</div>
			</div>
			<div class="subscription-years">
				<div class="initial">
					<div class="row">
						{props.subscription.Years.map((year) => (
							<div key={year.SubscriptionId ?? ""} class="col-6 col-sm py-1" data-id={year.SubscriptionId}>
								<small class="d-block">{year.Name}</small>
								<CurrencyInput
									value={year.Amount ?? 0}
									setValue={(amount) => (year.Amount = amount)}
									v-model={year.Amount}
									class="form-control form-control-sm required sub"
									style="max-width:95%;"
									data-validation="Please enter all subscription values for the items you have selected"
								/>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default defineComponent(EditSubscription, {
	subscription: requiredProp(Object),
})
