import { insertQualifyingCheck, saveClaimToServer } from "@/apis"
import calculatorConfig from "@/calculatorConfig"
import { useLoading } from "@/composition/useLoading"
import { selectableEmploymentYears } from "@/constants"
import ClaimSteps, { ClaimType } from "@/steps"
import { ClaimStatus } from "@/types"
import { SetClaimSetOptions, setClaimStep } from "./setStep"

const { runAction } = useLoading()

export default async function runQualifyingChecks(options?: SetClaimSetOptions): Promise<boolean> {
	// Not high enough earnings - go to step 99 (once we have their address), don't do anything else
	const earningsBand = calculatorConfig.earningsBands.find((band) => band.value === App.CustomerClaim.EarningsBand)
	const qualifies = earningsBand?.qualifies ?? false
	if (CurrentStep.type === ClaimType.Standard && !qualifies && CurrentStep.step > ClaimSteps.TaxYears.step) {
		App.CustomerClaim.ClaimStatus = ClaimStatus.CancelledNoTaxPaid
		App.CustomerClaim = await runAction(saveClaimToServer(App.CustomerClaim))

		void insertQualifyingCheck("Have you paid tax in the last 5 years?", "no", false, App.CustomerClaim.ClaimId, "TAX5")

		//if we don't yet have their address then let them carry on as they might be due a marriage rebate as the lowest earner
		const hasAddress = App.CustomerClaim.Address1 && App.CustomerClaim.Address1.trim().length > 0
		if (hasAddress) {
			await setClaimStep(ClaimSteps.NoTaxPaid, options)
			return false
		}
	} else if (CurrentStep.type === ClaimType.Standard && CurrentStep.step > ClaimSteps.TaxYears.step) {
		App.CustomerClaim.TaxYearIds = App.currentTaxYears.map((e) => e.Value)
		void insertQualifyingCheck("Have you paid tax in the last 5 years?", "yes", true, App.CustomerClaim.ClaimId, "TAX5")
	}

	// Claimed before - go to step 100, don't do anything else
	if (CurrentStep.type === ClaimType.Standard && App.CustomerClaim.ClaimedBefore === true) {
		App.CustomerClaim.ClaimStatus = ClaimStatus.CancelledClaimedBefore
		App.CustomerClaim = await runAction(saveClaimToServer(App.CustomerClaim))
		await setClaimStep(ClaimSteps.AlreadyClaimed, options)
		void insertQualifyingCheck("Have you claimed before?", "yes", false, App.CustomerClaim.ClaimId, "REP")
		return false
	} else {
		void insertQualifyingCheck("Have you claimed before?", "no", true, App.CustomerClaim.ClaimId, "REP")
	}

	// Self assessed - go to step 101. Cannot process claim.
	if (CurrentStep.type === ClaimType.Standard && App.CustomerClaim.selfAssessed === true) {
		App.CustomerClaim.ClaimStatus = ClaimStatus.CancelledSelfAssessed
		App.CustomerClaim = await runAction(saveClaimToServer(App.CustomerClaim))
		await setClaimStep(ClaimSteps.RequireSelfAssessment, options)

		void insertQualifyingCheck("Are you self-assessed?", "yes", false, App.CustomerClaim.ClaimId, "SA")
		return false
	} else if (CurrentStep.type === ClaimType.Standard && App.CustomerClaim.selfAssessed === false) {
		void insertQualifyingCheck("Are you self-assessed?", "no", true, App.CustomerClaim.ClaimId, "SA")
	} else {
		void insertQualifyingCheck("Are you self-assessed?", "unknown", false, App.CustomerClaim.ClaimId, "SA")
	}

	const { ClaimedUniformAllowanceCurrentJob, ClaimedUniformAllowancePreviousJob, Subscriptions } = App.CustomerClaim
	//if customer is married and doesn't qualify in their own right then skip this check - we'll let them pass because them
	//they might be due a marriage refund
	const possMarriageClaim =
		App.CustomerClaim.Married && ((App.CustomerClaim.SpouseEmployed && !qualifies) || (!App.CustomerClaim.SpouseEmployed && qualifies))
	if (!possMarriageClaim) {
		if (
			CurrentStep.type === ClaimType.Standard &&
			(ClaimedUniformAllowanceCurrentJob || ClaimedUniformAllowancePreviousJob) &&
			(!Subscriptions || Subscriptions.length === 0)
		) {
			App.CustomerClaim.ClaimStatus = ClaimStatus.CancelledWorksPartTime
			App.CustomerClaim = await runAction(saveClaimToServer(App.CustomerClaim))
			localStorage.setItem("CustomerClaim", JSON.stringify(App.CustomerClaim))
			await setClaimStep(ClaimSteps.UniformClaimed, options)
			void insertQualifyingCheck("Claimed uniform and not in a subscription industry?", "yes", false, App.CustomerClaim.ClaimId, "UNIF")
			return false
		} else {
			void insertQualifyingCheck("Claimed uniform and not in a subscription industry?", "no", true, App.CustomerClaim.ClaimId, "UNIF")
		}
	}

	//Not been paying tax long enough? - go to step defined above. Cannot process claim.
	if (CurrentStep.type === ClaimType.Standard && CurrentStep.step >= ClaimSteps.EmploymentHistory.step) {
		if (App.CustomerClaim.EmploymentRecords.length === 0) {
			App.CustomerClaim.ClaimStatus = ClaimStatus.CancelledNoEmploymentHistory
			App.CustomerClaim = await runAction(saveClaimToServer(App.CustomerClaim))
			await setClaimStep(ClaimSteps.NoEmploymentHistory, options)
			void insertQualifyingCheck(
				`Do you have any employment history in the past ${selectableEmploymentYears} years?`,
				"no",
				false,
				App.CustomerClaim.ClaimId,
				"TAX1"
			)
			return false
		} else {
			void insertQualifyingCheck(
				`Do you have any employment history in the past ${selectableEmploymentYears} years?`,
				"yes",
				true,
				App.CustomerClaim.ClaimId,
				"TAX1"
			)
		}
	}

	// If status = 54 (set by server, calculated that there is nothing to claim for - no uniform, subs or expenses - so claim ends)
	if (App.CustomerClaim.ClaimStatus === ClaimStatus.CancelledNothingToClaimFor) {
		await setClaimStep(ClaimSteps.LackOfExpenses, options)
		void insertQualifyingCheck("Is there anything to claim for?", "no", false, App.CustomerClaim.ClaimId, "ANYCL")
		return false
	}

	return true
}
