import { onMounted, onUnmounted } from "vue"

export const createMountedList = <T>() => {
	const registrations = new Map<symbol, T>()

	function use(value: T) {
		const symbol = Symbol()
		onMounted(() => {
			registrations.set(symbol, value)
		})
		onUnmounted(() => {
			registrations.delete(symbol)
		})
	}

	return {
		use,
		registrations,
	}
}
