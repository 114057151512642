/* eslint-disable jsx-a11y/label-has-associated-control */
import { occupationSearch } from "@/apis"
import createSearchSelect from "@/components/SearchSelect"
import type { EmploymentRecord, OccupationSearch } from "@/types"
import { toReactive } from "@vueuse/core"
import { ref, toRef } from "vue"
import { defineComponent, requiredProp, watchDebounce, type ReactiveComponent } from "vue-utils"
import EmploymentDates from "./EmploymentDates"
import IndustrySelect from "./components/IndustrySelect"
import OccupationSelect from "./components/OccupationSelect"
import { inputGroupStyles } from "./styles"

interface Props {
	record: EmploymentRecord
}

const JobSelect = createSearchSelect<OccupationSearch>()

const EmploymentJobDetails: ReactiveComponent<Props> = (props) => {
	const record = toReactive(toRef(props, "record"))
	const unknownJob = ref(false)

	const occupationSearchResults = ref<OccupationSearch[]>([])

	async function runOccupationSearch(search: string) {
		const results = search.length === 0 ? [] : await occupationSearch(search)
		unknownJob.value = unknownJob.value || results.length === 0
		occupationSearchResults.value = results
	}
	watchDebounce(toRef(record, "JobTitle"), () => void runOccupationSearch(record.JobTitle))

	function onSelectJob(job: OccupationSearch) {
		const newData: Partial<EmploymentRecord> = {
			IndustryId: job.IndustryId,
			OccupationId: job.OccupationId,
			JobTitle: job.Name,
		}
		Object.assign(record, newData)
		unknownJob.value = false
	}

	function updateJobSearch(search: string) {
		const newData: Partial<EmploymentRecord> = {
			JobTitle: search,
			OccupationId: null,
			IndustryId: null,
		}
		Object.assign(record, newData)
	}

	return () => (
		<section>
			<h5>Job Details</h5>
			<label class={inputGroupStyles}>
				<div class="input-group-prepend input-group-text">Job Title</div>
				<JobSelect
					style={{ flexGrow: 1 }}
					inputProps={{ class: "form-control", placeholder: "Search for your job", required: true }}
					getKey={(o) => o.Id.toString()}
					getText={(o) => o.Name}
					options={occupationSearchResults.value}
					searchTerm={record.JobTitle}
					setSearch={updateJobSearch}
					setOption={onSelectJob}
				/>
			</label>
			{unknownJob.value && (
				<label class={inputGroupStyles}>
					<div class="input-group-prepend input-group-text">Industry</div>
					<IndustrySelect
						industryId={record.IndustryId ?? -1}
						setIndustryId={(id) => {
							record.IndustryId = id
							record.OccupationId = -1
						}}
					/>
				</label>
			)}
			{unknownJob.value && record.IndustryId !== null && record.IndustryId > 0 && (
				<label class={inputGroupStyles}>
					<div class="input-group-prepend input-group-text">Job Type</div>
					<OccupationSelect
						industryId={record.IndustryId ?? undefined}
						occupationId={record.OccupationId ?? -1}
						setOccupationId={(id) => (record.OccupationId = id)}
					/>
				</label>
			)}
			<EmploymentDates record={record} />
		</section>
	)
}

export default defineComponent(EmploymentJobDetails, {
	record: requiredProp(Object),
})
