import ButtonNavigation from "@/components/ButtonNavigation"
import { type CustomCheck, useCustomCheck } from "@/composition/useCustomCheck"
import { pandemicYearIds } from "@/constants"
import { showConfirmModal } from "@/modals"
import type { Id, WorkFromHomeYear } from "@/types"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const PandemicWorkFromHomeCheck: CustomCheck = async () => {
	if (App.CustomerClaim.WorkFromHomeYears.filter((t) => t.Value && pandemicYearIds.includes(t.Id)).length >= 2) {
		return true
	}
	return await showConfirmModal({
		title: "Work from home during the pandemic",
		content: () => (
			<>
				<p>Even if you only worked from home for one day during 2020 or 2021, you are entitled to claim allowance for the entire year.</p>
				<p>Are you sure you don't want to claim for one or both of these years?</p>
			</>
		),
	})
}

const WorkFromHomeStep: ReactiveComponent = () => {
	useCustomCheck(PandemicWorkFromHomeCheck)

	function taxYearName(taxYearId: Id) {
		return App.currentTaxYears?.find((ty) => ty.Value === taxYearId)?.Key ?? ""
	}

	function renderYear(taxYear: WorkFromHomeYear) {
		return (
			<div key={taxYear.Id} class="d-table-row">
				<div class="d-table-cell p-2 align-middle text-left font-weight-bold">
					{`06/04/${taxYearName(taxYear.Id).split("/")[0].substr(2, 2)} to `}
					{`05/04/${taxYearName(taxYear.Id).split("/")[1]}`}
				</div>
				<div class="d-table-cell p-2 text-center">
					<label class="custom-control custom-control-inline custom-radio">
						<input
							type="radio"
							class="custom-control-input required"
							name={`taxyearWFH${taxYear.Id}`}
							value={true}
							v-model={taxYear.Value}
							data-validation={`You must select a work-from-home status for ${taxYearName(taxYear.Id)}`}
						/>
						<span class="custom-control-label ml-3"></span>
					</label>
				</div>
				<div class="d-table-cell p-2 text-center">
					<label class="custom-control custom-control-inline custom-radio">
						<input
							type="radio"
							class="custom-control-input required"
							name={`taxyearWFH${taxYear.Id}`}
							value={false}
							v-model={taxYear.Value}
							data-validation={`You must select a work-from-home status for ${taxYearName(taxYear.Id)}`}
						/>
						<span class="custom-control-label ml-3"></span>
					</label>
				</div>
				{taxYear.Value && !pandemicYearIds.includes(taxYear.Id) && (
					<div class="d-table-cell p-2">
						<input
							type="text"
							class="form-control required"
							name="WeeksFromHome"
							data-min-val="1"
							data-max-val="52"
							data-validation="Please input a number of weeks that you worked from home this year."
							v-model={taxYear.Weeks}
						/>
					</div>
				)}
			</div>
		)
	}

	return () => (
		<>
			<div class="col-12 my-2">
				<h3 class="py-2 border border-top-0 border-left-0 border-right-0">Working from home</h3>
				<p class="d-block my-2">
					Did your employer <strong>REQUIRE</strong> you to work from home for any period in the last 4 tax years?
				</p>
				<p class="d-block my-2">
					<strong>Years to 05/04/21 &amp; 05/04/22 ONLY:</strong> Due to COVID, you can claim for a full 52 weeks in the year, even if you only
					worked from home for 1 day.
				</p>
				<div class="d-table w-100">
					<div class="d-table-row border border-top-0 border-left-0 border-right-0">
						<div class="d-table-cell p-2 font-weight-bold w-40"></div>
						<div class="d-table-cell p-2 font-weight-bold text-uppercase align-middle w-20 text-center">Yes</div>
						<div class="d-table-cell p-2 font-weight-bold text-uppercase align-middle w-20 text-center">No</div>
						<div class="d-table-cell p-2 font-weight-bold text-uppercase align-middle w-20 text-center">Weeks</div>
					</div>
					{!!App.CustomerClaim.WorkFromHomeYears && App.currentTaxYears.length > 0 && App.CustomerClaim.WorkFromHomeYears.map(renderYear)}
				</div>
			</div>
			<ButtonNavigation />
		</>
	)
}

export default defineComponent(WorkFromHomeStep)
