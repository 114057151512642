import type { Nullable } from "@/types"
import { css } from "vite-css-in-js"
import { defineComponent, optionalProp, requiredProp, useOnInput, type ReactiveComponent } from "vue-utils"
import { inputGroupStyles } from "../styles"

interface Props {
	name: string
	value: Nullable<string>
	setValue: (value: Nullable<string>) => void
	placeholder: string
	disabled?: boolean
	dontKnowDisabled?: boolean
	highlightLabel?: boolean
}

const dontKnowFieldStyles = css`
	display: grid !important;
	grid-template-columns: max-content max-content 1fr;

	& > label {
		column-gap: 0.35rem;
		background-color: white !important;
		border: 1px solid #ced4da !important;
		user-select: none;
	}
	& > div:nth-child(2) {
		line-height: initial;

		&:not([data-disabled="true"]) {
			background-color: white;
		}
		&[data-disabled="true"] {
			opacity: 0.75;
		}

		gap: 0.35rem;
		input {
			flex-grow: initial;
		}
	}

	@media (max-width: 48rem) {
		margin-top: 0.5rem !important;
		grid-template-columns: max-content auto;
		& > input {
			grid-column-start: span 2;
		}
	}
`

const DontKnowField: ReactiveComponent<Props> = (props) => {

	const labelClasses = `input-group-prepend input-group-text ${props.highlightLabel && 'border-danger text-danger'}`
	return () => (
		<div class={[inputGroupStyles, dontKnowFieldStyles]}>
			<div class={labelClasses} >{props.name}</div>
			{!props.dontKnowDisabled && <label class="input-group-text" data-disabled={String([props.disabled ?? props.dontKnowDisabled ?? false])}>
				<input
					type="checkbox"
					checked={props.value === null}
					onChange={(e) => props.setValue((e.target as HTMLInputElement).checked ? null : "")}
					disabled={props.disabled || props.dontKnowDisabled}
					style={{ height: "1.5rem" }}
				/>
				I don't know this
			</label>}
			{(props.value !== null || props.dontKnowDisabled) && (
				<input
					type="text"
					value={props.value}
					onInput={useOnInput(props.setValue)}
					class="form-control"
					placeholder={props.placeholder}
					readonly={props.disabled}
					required
				/>
			)}
		</div>
	)
}

export default defineComponent(DontKnowField, {
	name: requiredProp(String),
	value: requiredProp(String, null),
	setValue: requiredProp(Function),
	placeholder: requiredProp(String),
	disabled: optionalProp(Boolean),
	dontKnowDisabled: optionalProp(Boolean),
	highlightLabel: optionalProp(Boolean)
})
