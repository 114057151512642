/* eslint-disable jsx-a11y/label-has-associated-control */
import { showConfirmModal } from "@/modals"
import type { EmploymentRecord } from "@/types"
import { toReactive } from "@vueuse/core"
import { computed, toRef } from "vue"
import { defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"
import EmployerDetails from "./EmployerDetails"
import EmploymentJobDetails from "./EmploymentJobDetails"
import EmploymentUniform from "./EmploymentUniform"
import { containerStyles } from "./styles"

interface Props {
	number: number
	record: EmploymentRecord
	setRecord(record: EmploymentRecord): void
	highlightPAYE: boolean
	remove?: () => void
}

const EditEmployment: ReactiveComponent<Props> = (props) => {
	const record = toReactive(toRef(props, "record"))
	const hasJobDetails = computed(() => record.JobTitle.trim().length > 0 && record.OccupationId !== null && record.OccupationId > 0)

	async function handleRemove() {
		const result = await showConfirmModal({
			title: "Confirm Remove Job",
			content: "Are you sure you wish to remove this job?",
			cancelText: "No",
			confirmText: "Yes",
		})
		if (result && props.remove) {
			props.remove()
		}
	}

	return () => (
		<fieldset class={["input-group", containerStyles]}>
			<legend class="d-flex align-items-center justify-content-between">
				<span style={{ fontSize: "1.25rem" }}>Job {props.number}</span>
				{ props.remove && <button
					type="button"
					class="btn btn-danger"
					style={{ borderRadius: "0.5rem", border: "none", padding: "0.25rem 0.75rem" }}
					onClick={() => void handleRemove()}
					>
					<i class="fa fa-trash" />
				</button> }
			</legend>

			<EmploymentJobDetails record={record} />
			{hasJobDetails.value && <EmploymentUniform record={record} />}
			{hasJobDetails.value && <EmployerDetails highlightPAYE={props.highlightPAYE} record={record} />}
		</fieldset>
	)
}

export default defineComponent(EditEmployment, {
	number: requiredProp(Number),
	record: requiredProp(Object),
	setRecord: requiredProp(Function),
	highlightPAYE: requiredProp(Boolean),
	remove: optionalProp(Function),
})
