import { defineComponent, optionalProp, requiredProp, useOnInputNumber, type ReactiveComponent } from "vue-utils"

interface Props {
	month: number
	setMonth(month: number): void
	enableMonth?: (month: number) => boolean
}
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

const MonthSelect: ReactiveComponent<Props> = (props) => {
	return () => (
		<select class="form-control" value={props.month <= 0 ? "" : props.month} onInput={useOnInputNumber(props.setMonth)} required>
			<option value="" disabled>
				Month
			</option>
			{months.map((month, i) => (
				<option key={i} value={i + 1} disabled={!!props.enableMonth && !props.enableMonth(i + 1)}>
					{month}
				</option>
			))}
		</select>
	)
}

export default defineComponent(MonthSelect, {
	month: requiredProp(Number),
	setMonth: requiredProp(Function),
	enableMonth: optionalProp(Function),
})
