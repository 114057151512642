import { defineComponent, type ReactiveComponent } from "vue-utils"

const NoEmploymentHistory: ReactiveComponent = () => {
	return () => (
		<div class="col-12 my-1">
			<div class="jumbotron">
				<h2 class="xbold">Sorry - you don't qualify for a claim</h2>
				<p>
					Based on your employment history, you have not been employed long enough to qualify for a rebate. I'm afraid this means we will be
					unable to process your claim.
				</p>
			</div>
		</div>
	)
}

export default defineComponent(NoEmploymentHistory)
