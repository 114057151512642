import calculatorConfig from "@/calculatorConfig"
import ButtonNavigation from "@/components/ButtonNavigation"
import { useCustomCheck } from "@/composition/useCustomCheck"
import { showConfirmModal } from "@/modals"
import { computed } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const SubscriptionsStep: ReactiveComponent = () => {
	const enabledSubscriptions = computed(() => App.CustomerClaim.Subscriptions.filter((sub) => sub.Enabled))

	useCustomCheck(async () => {
		if (enabledSubscriptions.value.length > 0) {
			return true
		}
		return await showConfirmModal({
			title: "Confirm Subscriptions",
			content: () => "You have not selected any applicable subscriptions. Please check this is correct.",
			cancelText: "Oops - I'd like to select some subscriptions",
			confirmText: "This is correct",
		})
	})

	return () => (
		<>
			<div class="col-12 my-2">
				<h3 class="bold pb-2">Subscriptions &amp; Expenses</h3>
				<p>
					Have you paid subscriptions or expenses for any of the following? You can select a maximum of {calculatorConfig.maximumSubscriptions}{" "}
					here.
				</p>
				{App.CustomerClaim.Subscriptions.map((sub) => (
					<div key={sub.OccupationSubscriptionId} class="Subscription pb-2" data-subscriptionid={sub.OccupationSubscriptionId}>
						<div class="py-1">
							<div>
								<label class="custom-control custom-control-inline custom-checkbox">
									<input
										type="checkbox"
										class="custom-control-input"
										v-model={sub.Enabled}
										id={`subscription${sub.OccupationSubscriptionId}`}
										disabled={!sub.Enabled && enabledSubscriptions.value.length >= calculatorConfig.maximumSubscriptions}
									/>
									<span class="custom-control-label">{sub.Name}</span>
								</label>
							</div>
						</div>
					</div>
				))}
			</div>
			<ButtonNavigation />
		</>
	)
}

export default defineComponent(SubscriptionsStep)
