import { unref } from "vue"
import type { Component } from "vue-utils"
import type { ClaimStepData } from "."
import ClaimSteps from "."
import AlreadyClaimed from "./issues/AlreadyClaimed"
import EmployerDetailsNeeded from "./issues/EmployerDetailsNeeded"
import LackOfExpenses from "./issues/LackOfExpenses"
import NoTaxPaid from "./issues/NoTaxPaid"
import NoEmploymentHistory from "./issues/NoEmploymentHistory"
import RequireSelfAssessment from "./issues/RequireSelfAssessment"
import UniformClaimed from "./issues/UniformClaimed"
import WaitUntilNextTaxYear from "./issues/WaitUntilNextTaxYear"
import AboutYouStep from "./mod/AboutYouStep"
import BasicInformationStep from "./mod/BasicInformationStep"
import DetailsPassedStep from "./mod/DetailsPassedStep"
import AddressStep from "./standard/AddressStep"
import CompleteStep from "./standard/CompleteStep"
import EmploymentHistoryStep from "./standard/employment"
import EstimateStep from "./standard/EstimateStep"
import NameStep from "./standard/NameStep"
import NationalInsuranceStep from "./standard/NationalInsuranceStep"
import PostcodeStep from "./standard/PostcodeStep"
import QualifyingStep from "./standard/QualifyingStep"
import ReviewStep from "./standard/ReviewStep"
import SelfAssessedChecks from "./standard/SelfAssessedChecksStep"
import SignatureStep from "./standard/SignatureStep"
import SubscriptionDetails from "./standard/SubscriptionDetailsStep"
import SubscriptionsStep from "./standard/SubscriptionsStep"
import TaxYearsStep from "./standard/TaxYearsStep"
import WorkFromHomeStep from "./standard/WorkFromHomeStep"
import DetailsRejected from "./issues/DetailsRejected"

/*
   Step components are declared separately to the step information data so that the step components can reference other 
   steps without causing errors from circular imports
*/

const stepComponents = new Map<ClaimStepData, Component>()

stepComponents.set(ClaimSteps.Postcode, PostcodeStep)
stepComponents.set(ClaimSteps.Name, NameStep)
stepComponents.set(ClaimSteps.TaxYears, TaxYearsStep)
stepComponents.set(ClaimSteps.Qualifying, QualifyingStep)
stepComponents.set(ClaimSteps.Address, AddressStep)
stepComponents.set(ClaimSteps.SelfAssessedChecks, SelfAssessedChecks)
stepComponents.set(ClaimSteps.Estimate, EstimateStep)
stepComponents.set(ClaimSteps.WorkFromHome, WorkFromHomeStep)
stepComponents.set(ClaimSteps.Subscriptions, SubscriptionsStep)
stepComponents.set(ClaimSteps.EmploymentHistory, EmploymentHistoryStep)
stepComponents.set(ClaimSteps.SubscriptionDetails, SubscriptionDetails)
stepComponents.set(ClaimSteps.NationalInsurance, NationalInsuranceStep)
stepComponents.set(ClaimSteps.ReviewClaim, ReviewStep)
stepComponents.set(ClaimSteps.Signature, SignatureStep)
stepComponents.set(ClaimSteps.ClaimComplete, CompleteStep)

stepComponents.set(ClaimSteps.BasicInformation, BasicInformationStep)
stepComponents.set(ClaimSteps.AboutYou, AboutYouStep)
stepComponents.set(ClaimSteps.DetailsPassed, DetailsPassedStep)

stepComponents.set(ClaimSteps.NoTaxPaid, NoTaxPaid)
stepComponents.set(ClaimSteps.AlreadyClaimed, AlreadyClaimed)
stepComponents.set(ClaimSteps.RequireSelfAssessment, RequireSelfAssessment)
stepComponents.set(ClaimSteps.NoEmploymentHistory, NoEmploymentHistory)
stepComponents.set(ClaimSteps.LackOfExpenses, LackOfExpenses)
stepComponents.set(ClaimSteps.UniformClaimed, UniformClaimed)
stepComponents.set(ClaimSteps.EmployerDetailsNeeded, EmployerDetailsNeeded)
stepComponents.set(ClaimSteps.WaitUntilNextTaxYear, WaitUntilNextTaxYear)
stepComponents.set(ClaimSteps.DetailsRejected, DetailsRejected)

export const getStepComponent = (step: ClaimStepData) => stepComponents.get(unref(step))
