import { runCustomChecks } from "@/composition/useCustomCheck"
import { runCustomValidation } from "@/composition/useCustomValidation"
import { runCustomInputValidation } from "@/composition/useInputValidation"
import { showAlertModal } from "@/modals"
import type { Nullable } from "@/types"
import moment from "moment"
import { delay } from "vue-utils"

/*VALIDATION FUNCTIONS*/
export async function checkFields(sender: JQuery<Element>): Promise<boolean> {
	runCustomInputValidation()

	const form = sender.closest("form").get(0)
	if (form && form instanceof HTMLFormElement && !form.reportValidity()) {
		return false
	}

	let invalidFieldCount = 0
	const errorMessages: string[] = []

	function addElementError<T extends Element>(selector: JQuery<T>) {
		const errorMessage = selector.attr("data-validation")
		if (errorMessage) {
			errorMessages.push(errorMessage)
		}
	}

	//check NI fields
	$("input.NI:visible").each(function () {
		const f = $(this)
		if (f.val() !== "" && !validateNI(String(f.val()).toUpperCase())) {
			f.addClass("warn")
			invalidFieldCount++
			errorMessages.push("Please input your National Insurance number in the format AA999999B - all in capitals with no spaces.<br/>")
		} else {
			f.removeClass("warn")
		}
	})

	//check email fields
	$('input[type="email"]:visible').each(function () {
		const f = $(this)
		const val = f.val()
		if (f.val() !== "" && (typeof val !== "string" || !validateEmail(val))) {
			f.addClass("warn")
			invalidFieldCount++
			addElementError(f)
		} else {
			f.removeClass("warn")
		}
	})

	//check text fields
	let id: string | undefined = ""
	$("input.required:visible:not([disabled])").each(function () {
		const f = $(this)
		if (String(f.val()).length < 1) {
			f.addClass("warn")

			//check that another control in the group hasn't already triggered the validation
			//used mostly for the new NI 'fragment' input where one value represented by multiple inputs
			//otherwise multiple validation messages are returned - one for each input - which looks messy.
			if (id !== f.attr("data-validation-groupcontrolid")) {
				invalidFieldCount++
				const t = f.attr("data-validation") ?? `${f.attr("name") ?? ""} cannot be blank`
				errorMessages.push(t)
			}
			id = f.attr("data-validation-groupcontrolid") ?? ""
		} else if (
			(f.attr("data-min-val") && (f.attr("data-min-val") as string).length > 0) ||
			(f.attr("data-max-val") && (f.attr("data-max-val") as string).length > 0)
		) {
			const min = parseInt(f.attr("data-min-val") as string)
			let test = parseInt(String(f.val()))
			if (test < min) {
				invalidFieldCount++
				const t = f.attr("data-validation")
					? `${f.attr("data-validation") as string} Value cannot be less than ${min}`
					: `${f.attr("name") ?? ""} cannot be less than ${min}`

				errorMessages.push(t)
			}
			const max = parseInt(f.attr("data-max-val") as string)
			test = parseInt(String(f.val()))
			if (test > max) {
				invalidFieldCount++
				const t = f.attr("data-validation")
					? `${f.attr("data-validation") as string} Value cannot be more than ${max}`
					: `${f.attr("name") ?? ""} cannot be more than${max}`

				errorMessages.push(t)
			}
		} else {
			f.removeClass("warn")
		}
	})

	//check phone fields
	$('input[type="tel"].mobile:visible').each(function () {
		const f = $(this)
		const val = f.val()
		if (val !== "" && (typeof val !== "string" || !validateUKMobile(val))) {
			f.addClass("warn")
			invalidFieldCount++
			addElementError(f)
		} else {
			f.removeClass("warn")
		}
	})

	// check hidden
	$('input[type="hidden"].required').each(function () {
		const f = $(this)
		if (f.val() === "" || (f.hasClass("number") && parseInt(String(f.val())) < 0)) {
			f.addClass("warn")
			invalidFieldCount++
			const t = f.attr("data-validation") ?? `${f.attr("name") ?? ""} cannot be blank`
			errorMessages.push(t)
		} else {
			f.removeClass("warn")
		}
	})

	//check date fields
	$("input.dateformat:visible").each(function () {
		const f = $(this)
		const dateValue = f.val()
		const r = typeof dateValue === "string" ? isValidDate(dateValue) : { valid: false }
		if (!r.valid) {
			f.addClass("warn")
			invalidFieldCount++
			const t = f.attr("data-validation") ?? `${f.attr("name") ?? ""} value is invalid`
			errorMessages.push(t)
		} else if (f.hasClass("dob")) {
			const maxdob = moment(maxDOB, "YYYY/MM/DD")
			const dob = moment(f.val(), "D/M/YYYY")
			if (dob.isAfter(maxdob)) {
				f.addClass("warn")
				invalidFieldCount++
				errorMessages.push("Please input a valid date of birth - you must be at least 18 years of age.")
			} else {
				f.removeClass("warn")
			}
		} else if (!f.hasClass("required")) {
			f.removeClass("warn")
		}
	})

	//check checkbox fields
	$('input[type="checkbox"].required').each(function () {
		const n = $(this).attr("name")
		if (n && n.length > 0 && $(`input[type="checkbox"][name="${n}"].required`).length > 1) {
			// multiple checkboxes with the same name attribute, only one needs to be checked to continue
			if ($(this).is($(`input[type="checkbox"][name="${n}"].required`)[0])) {
				// process first one only, check at least one is checked
				if (sender.closest(".step").find($(this)).length) {
					const f = $(this)
					if ($(`input[type="checkbox"][name="${n}"].required:checked`).length === 0) {
						//f.addClass('warn');
						$(`input[type="checkbox"][name="${n}"].required`).addClass("warn")
						invalidFieldCount++
						//message += (f.attr('data-validation') + '<br/>');
						// Url decode - encode at https://mothereff.in/html-entities
						// Alt script library https://github.com/mathiasbynens/he
						addElementError(f)
						//message += ('<div class="mb-2">' + decodeHTML(f.attr('data-validation')) + '</div>');
					} else {
						//f.removeClass('warn');
						$(`input[type="checkbox"][name="${n}"].required`).removeClass("warn")
					}
				}
			}
		} else if (sender.closest(".step").find($(this)).length) {
			// logic for single checkboxes

			const f = $(this)
			if (!f.is(":checked")) {
				f.addClass("warn")
				invalidFieldCount++
				addElementError(f)
			} else {
				f.removeClass("warn")
			}
		}
	})

	//check radiobutton fields
	$('input[type="radio"].required').each(function () {
		if (sender.closest(".step").find($(this)).length) {
			const f = $(this)
			const group = f.attr("name") ?? ""

			if (!f.is(":checked") && !$(`input[type="radio"][name="${group}"]:checked`).length) {
				//if ($('input[name=' + group + ']:checked').length) {
				f.addClass("warn")
				invalidFieldCount++
				errorMessages.push(f.attr("data-validation") ?? "")
			} else {
				f.removeClass("warn")
			}
		}
	})

	//check dropdowns
	$("select.required:visible").each(function () {
		const f = $(this)
		if (f.val() === "" || f.val() === "-1" || f.val() === "na" || !f.val()) {
			f.addClass("warn")
			invalidFieldCount++
			addElementError(f)
		} else {
			f.removeClass("warn")
		}
	})

	//check 'MATCH' fields
	let t = ""
	$("input.match:visible").each(function () {
		const f = $(this)
		if (t === "") t = String(f.val()).toLowerCase()
		if (String(f.val()).toLowerCase() !== t.toLowerCase()) {
			f.addClass("warn")
			invalidFieldCount++
			errorMessages.push("Field Values must match - please check.")
		} else {
			f.removeClass("warn")
		}
	})

	for (const result of await runCustomValidation()) {
		if (typeof result === "string" && result.length > 0) {
			invalidFieldCount++
			errorMessages.push(result)
		} else if (result === false) {
			invalidFieldCount++
		}
	}

	if (errorMessages.length > 0) {
		await showAlertModal({
			title: "Missing information",
			content: () => (
				<ul style={{ margin: 0, listStyle: "none", padding: 0 }}>
					{errorMessages.map((msg, i) => (
						<li key={i}>{msg}</li>
					))}
				</ul>
			),
		})
		await delay(250)
		return false
	}

	if (invalidFieldCount > 0) {
		return false
	}

	return await runCustomChecks()
}

export function decodeHTML(html: string) {
	const txt = document.createElement("textarea")
	txt.innerHTML = html
	return txt.value
}

export function validateEmail(email: string) {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return re.test(email)
}

export function validateUKMobile(inp: string) {
	const re = /((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}/
	return re.test(inp)
}

export function validateNI(NI: string) {
	const re = /^[A-CEGHJ-PR-TW-Z]{1}[A-CEGHJ-NPR-TW-Z]{1}[0-9]{6}[A-DFM]{0,1}$/
	return re.test(NI)
}

export function isValidDate(str: Nullable<string>): { valid: boolean; tidiedDate: string } {
	//return valid for empty string as null is acceptable...
	if (!str || str.length === 0) return { valid: true, tidiedDate: "" }

	//fix 2 digit years
	const a = str.split("/")
	let y = a[a.length - 1]
	if (testMode) console.log(y)
	if (y.length === 2) {
		const yint = parseInt(y)
		if (yint > 70 && yint <= 99) {
			y = `19${y}`
		} else {
			y = `20${y}`
		}
		a[a.length - 1] = y
		str = a.join("/")
		if (testMode) console.log(str)
	}

	let d = moment(str, "D/M/YYYY")
	if (!d || !d.isValid()) {
		//Try a different format
		str = `01/${str}`
		d = moment(str, "D/M/YYYY")
	}
	if (!d.isValid()) {
		return {
			valid: false,
			tidiedDate: "",
		}
	}

	const OK =
		str.indexOf(d.format("D/M/YYYY")) >= 0 ||
		str.indexOf(d.format("DD/MM/YYYY")) >= 0 ||
		str.indexOf(d.format("D/M/YY")) >= 0 ||
		str.indexOf(d.format("DD/MM/YY")) >= 0

	return { valid: OK, tidiedDate: str }
}
