﻿import { renderSlot, type InputHTMLAttributes } from "vue"
import { defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	name: string
	value: boolean | null | undefined
	setValue?: (newValue: boolean) => void
	required?: boolean
	readonly?: boolean
	legend?: string
}

const RadioButton: ReactiveComponent<Props, InputHTMLAttributes> = (props, { emit, attrs, slots }) => {
	function setValue(value: boolean) {
		emit("input", value)
		props.setValue?.(value)
	}

	return () => (
		<fieldset class="radio-buttons">
			{(props.legend || "default" in slots) && <legend>{props.legend ? props.legend : renderSlot(slots, "default")}</legend>}
			<div>
				<label class="custom-control custom-control-inline custom-radio">
					<input
						ref="offButton"
						type="radio"
						name={props.name}
						value="false"
						class={["custom-control-input", props.required && "required"]}
						required={props.required}
						disabled={props.readonly}
						checked={props.value === false}
						onInput={() => setValue(false)}
						{...attrs}
					/>
					<span class="custom-control-label">No</span>
				</label>
				<label class="custom-control custom-control-inline custom-radio">
					<input
						ref="onButton"
						type="radio"
						name={props.name}
						value="true"
						class={["custom-control-input", props.required && "required"]}
						required={props.required}
						disabled={props.readonly}
						checked={props.value === true}
						onInput={() => setValue(true)}
					/>
					<span class="custom-control-label">Yes</span>
				</label>
			</div>
		</fieldset>
	)
}

export default defineComponent(RadioButton, {
	name: requiredProp(String),
	value: requiredProp(Boolean, null),
	setValue: optionalProp(Function),
	legend: optionalProp(String),
	required: optionalProp(Boolean),
	readonly: optionalProp(Boolean),
})
