import { css } from "vite-css-in-js"

export const containerStyles = css`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.75rem;
	border: 1px solid #0000005c;
	border-radius: 0.5rem;
	background-color: #f7f7f7;
	padding: 1rem 0.75rem;
	margin-bottom: 2rem;
	margin-top: 2.25rem;
	box-shadow: 5px 5px 5px #0000007d;

	& > legend {
		position: absolute;
		top: -2.25rem;
		padding-right: 1rem;
	}

	h5 {
		font-size: 1rem;
		margin: 0;
		margin-bottom: 0.5rem;
	}

	section {
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: stretch;
		gap: 0.125rem;
		margin: 0;
		margin-bottom: 0.5rem;

		p {
			font-size: 0.9rem !important;
			margin: 0;
			margin-bottom: 0.25rem;
		}
	}

	.radio-buttons {
		text-align: left;
		padding: 0;
		label {
			background-color: transparent;
			border: none;
			margin-top: 0;
			margin-bottom: 0;
		}
		legend {
			margin: 0;
			margin-bottom: 0.25rem;
			font-size: 0.92rem !important;
		}
	}
`

export const inputGroupStyles = css`
	display: flex;
	gap: 0.125rem;
	border: none !important;
	margin: 0;
	background-color: transparent !important;

	.input-group-prepend {
		width: 6rem;
		font-size: 0.9rem;
		padding: 0.4rem 0.5rem;
	}
	select,
	input {
		flex-grow: 1;
		flex-basis: 0;
		font-size: 0.95rem;
	}
`
