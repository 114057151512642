import ClaimSteps, { ClaimStepData, ClaimType } from "@/steps"
import { fixHeadFoot } from "@/utils/miscPageUtils"
import { focusClaimForm } from "@/utils/scrolling"
import { awaitNavPop, createHistoryState, getHistoryState } from "./history"
import { ignoreNavigation } from "./state"

export interface SetClaimSetOptions {
	ignoreHistory?: boolean
	initialLoad?: boolean
}

async function setUpNavigation() {
	const current = getHistoryState(history.state)
	ignoreNavigation.value = true
	if (!current) {
		history.replaceState(createHistoryState("back"), "")
		history.pushState(createHistoryState("current"), "")
		history.pushState(createHistoryState("forward"), "")
		await awaitNavPop()
	} else if (current === "back") {
		history.pushState(createHistoryState("current"), "")
		history.pushState(createHistoryState("forward"), "")
		await awaitNavPop()
	} else if (current === "forward") {
		await awaitNavPop()
	}
	ignoreNavigation.value = false
}

export async function setClaimStep(step: ClaimStepData, options: SetClaimSetOptions = {}) {
	const { ignoreHistory = false, initialLoad = false } = options

	dataLayer.push({ ClaimId: App.CustomerClaim.ClaimId }) //push claim id to Google Tag Manager datalayer...

	if (!ignoreHistory) {
		await setUpNavigation()
	}

	localStorage.setItem("ClaimType", step.type)
	localStorage.setItem("ClaimStage", step.name)

	CurrentStep = step

	if (step.isIssue || (!initialLoad && (step.type !== ClaimType.Standard || step.step > ClaimSteps.Name.step))) {
		App.IsQuickEstimate = false
	}

	if (!initialLoad) {
		setTimeout(() => focusClaimForm(false), 50)
	}
	fixHeadFoot()
}
