import ButtonNavigation from "@/components/ButtonNavigation"
import RadioButton from "@/components/RadioButton"
import { useCustomCheck } from "@/composition/useCustomCheck"
import { showConfirmModal } from "@/modals"
import { toReactive } from "@vueuse/core"
import { computed } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import EditEmployment from "./EditEmployment"

async function confirmNoRecords() {
	return await showConfirmModal({
		title: "Confirm Employment History",
		content: () => (
			<>
				<p>
					<i class="fa fa-exclamation-triangle"></i> You have not added any employment records.
				</p>
				<p>
					To improve your chance of receiving a rebate we <i>strongly</i> recommend listing <strong>all jobs for the last 5 years</strong>.
				</p>
			</>
		),
		cancelText: "Add more jobs",
		confirmText: "I have listed all jobs",
	})
}

const EmploymentHistoryStep: ReactiveComponent = () => {
	const records = toReactive(computed(() => App.CustomerClaim.EmploymentRecords))
	const recordsMissingPAYE = computed(() =>
		records.some((record) => record.PAYEReference === null)
	)

	function addEmptyJob() {
		let newId = 1
		while (records.some((r) => r.EmploymentId === newId)) {
			newId++
		}
		App.CustomerClaim.EmploymentRecords = [
			...records,
			{
				EmploymentId: newId,
				JobTitle: "",
				IndustryId: null,
				OccupationId: null,
				Uniformed: true,
				AddressLine1: "",
				EmployerName: "",
				PAYEReference: "",
				Postcode: "",
				Started: {
					Year: -1,
					Month: -1,
				},
				Finished: {
					Year: -1,
					Month: -1,
				},
			},
		]
	}

	useCustomCheck(async () => {
		if (records.length > 0) {
			return true
		}
		return await confirmNoRecords()
	})

	return () => (
		<div class="d-flex flex-column" style={{ alignItems: "center", width: "100%" }}>
			<div class="text-center">
				<h3 class="bold mb-2">About your employment history</h3>
				<p>Enter details of your employment history in the past 5 years.</p>
				<hr class="mt-0" style={{ width: "100%" }} />
				{records.length === 0 && (
					<p>
						<small>You have not yet added any jobs.</small>
					</p>
				)}
			</div>

			{records.map((record, i) => (
				<EditEmployment
					key={record.EmploymentId}
					number={i + 1}
					record={record}
					setRecord={(newRecord) => Object.assign(record, newRecord)}
					remove={() => (App.CustomerClaim.EmploymentRecords = records.filter((r) => r.EmploymentId !== record.EmploymentId))}
					highlightPAYE={false}
				/>
			))}

			<div class="d-flex justify-content-end mb-4">
				{records.length < 6 && (
					<button type="button" class="btn btn-dark" style={{ textTransform: "none" }} onClick={() => addEmptyJob()}>
						<i class="fa fa-plus" /> {records.length === 0 ? "Add Job" : "Add Another Job"}
					</button>
				)}
			</div>
			<div class="d-flex justify-content-end mb-4">
				{recordsMissingPAYE && (
					<p class="alert alert-danger">Your claim will be processed far more quickly if you are able to provide PAYE references for all of your employers.</p>
				)}
			</div>
			<br />
			<ButtonNavigation />
		</div>
	)
}

export default defineComponent(EmploymentHistoryStep)
