import ButtonNavigation from "@/components/ButtonNavigation"
import { useCustomCheck } from "@/composition/useCustomCheck"
import { useCustomValidation } from "@/composition/useCustomValidation"
import { showConfirmModal } from "@/modals"
import { computed } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import EditSubscription from "./components/EditSubscription"

const SubscriptionDetailsStep: ReactiveComponent = () => {
	const enabledSubscriptions = computed(() => App.CustomerClaim.Subscriptions.filter((sub) => sub.Enabled))

	useCustomValidation(() => {
		if (enabledSubscriptions.value.some((sub) => sub.Years.some((year) => year.Amount === null))) {
			return "Please specify a value for all years of each subscription"
		}
		return true
	})

	useCustomCheck(async () => {
		const anyBelowThreshold = enabledSubscriptions.value.some((sub) => sub.Years.some((year) => year.Amount !== null && year.Amount < 50))
		if (!anyBelowThreshold) {
			return true
		}
		return await showConfirmModal({
			title: "Confirm Subscriptions",
			content: () => (
				<>
					Please check your subscription figures and make sure you have input the <span class="bold">ANNUAL</span> amount.
				</>
			),
			cancelText: "Oops - I'd like to correct the amounts.",
			confirmText: "The amounts are correct.",
		})
	})

	return () => (
		<>
			<div class="col-12 my-2">
				<h3 class="bold">Subscriptions &amp; Expenses</h3>
				{enabledSubscriptions.value.length === 0 ? (
					<p>You have not selected any available subscriptions or expenses.</p>
				) : (
					<>
						<p>
							Please enter the <span class="bold">ANNUAL</span> amount you paid for each year. If you pay monthly, multiply the amount by 12 to
							get to the figure you enter. Suggested subscription payments are based on typical rates. Edit these if your payments were
							different.
						</p>
						{App.CustomerClaim.Subscriptions.filter((sub) => sub.Enabled).map((sub) => (
							<EditSubscription key={sub.OccupationSubscriptionId} subscription={sub} />
						))}
					</>
				)}
			</div>
			<ButtonNavigation />
		</>
	)
}

export default defineComponent(SubscriptionDetailsStep)
