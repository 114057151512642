import type { ClaimStepData } from "@/steps"
import { getStepComponent } from "@/steps/StepComponents"
import { h, renderSlot } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	step: ClaimStepData
}

const ClaimStep: ReactiveComponent<Props> = (props, { slots }) => {
	function renderComponent() {
		if ("default" in slots) {
			return renderSlot(slots, "default")
		}
		const component = getStepComponent(props.step)
		if (!component) {
			return null
		}
		return h(component)
	}
	return () => (
		<form
			class="d-flex flex-wrap step p"
			data-step-name={props.step.name ?? ""}
			data-step-no={props.step.step}
			data-claim-type={props.step.name}
			onSubmit={(e) => e.preventDefault()}
		>
			{renderComponent()}
		</form>
	)
}

export default defineComponent(ClaimStep, {
	step: requiredProp(Object),
})
