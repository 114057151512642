import { defineComponent, type ReactiveComponent } from "vue-utils"

const UniformBenefits: ReactiveComponent = () => {
	return () =>
		App.CustomerClaim.ThirdPtyPermission ? (
			<p>
				You can still claim lots of exclusive discounts at{" "}
				<strong><a href="https://www.uniformbenefits.com">UniformBenefits.com</a></strong>
			</p>
		) : (
			<p>
					You can still subscribe to <a href="https://www.uniformbenefits.com">UniformBenefits.com</a> to claim lots of exclusive discounts.
			</p>
		)
}

export default defineComponent(UniformBenefits)
