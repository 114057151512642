import { createMountedList } from "./mountedList"

export type CustomValidation = () => ValidationResult | Promise<ValidationResult>
export type ValidationResult = boolean | string

const { use, registrations } = createMountedList<CustomValidation>()

export const useCustomValidation = use

export async function runCustomValidation(): Promise<ValidationResult[]> {
	const result: ValidationResult[] = []
	for (const validate of registrations.values()) {
		result.push(await validate())
	}
	return result
}
