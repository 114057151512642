import { defineComponent, requiredProp, useOnInput, type ReactiveComponent } from "vue-utils"

interface Props {
	title: string
	setTitle(title: string): void
}

const SelectTitle: ReactiveComponent<Props> = (props) => {
	return () => (
		<select class="form-control" value={props.title} onInput={useOnInput(props.setTitle)} required>
			<option value="" disabled>
				Select your title
			</option>
			<option value="Mr">Mr</option>
			<option value="Mrs">Mrs</option>
			<option value="Miss">Miss</option>
			<option value="Ms">Ms</option>
			<option value="Dr">Dr</option>
			<option value="Rev">Rev</option>
		</select>
	)
}

export default defineComponent(SelectTitle, {
	title: requiredProp(String),
	setTitle: requiredProp(Function),
})
