/* eslint-disable jsx-a11y/label-has-associated-control */
import type { EmploymentRecord } from "@/types"
import { toReactive } from "@vueuse/core"
import { css } from "vite-css-in-js"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import MonthSelect from "./components/MonthSelect"
import { inputGroupStyles } from "./styles"
import YearSelect from "./components/YearSelect"
import { toRef } from "vue"
import { selectableEmploymentYears } from "@/constants"

interface Props {
	record: EmploymentRecord
}

const monthsStyles = css`
	display: flex;
	gap: 0.125rem 1rem;
	width: 100%;

	@media (max-width: 40rem) {
		flex-direction: column;
	}

	& > label {
		flex-basis: 0;
		flex-grow: 1;
	}
`

const EmploymentDates: ReactiveComponent<Props> = (props) => {
	const record = toReactive(toRef(props, "record"))

	const currentYear = new Date().getFullYear()
	const currentMonth = new Date().getMonth() + 1
	const endYear = currentYear
	const startYear = endYear - (selectableEmploymentYears - 1)

	return () => (
		<div class={monthsStyles}>
			<label class={inputGroupStyles}>
				<div class="input-group-prepend input-group-text">Started</div>
				<YearSelect
					start={startYear}
					end={endYear}
					max={record.Finished && record.Finished.Year > 0 ? Math.min(endYear, record.Finished.Year) : endYear}
					year={record.Started?.Year ?? null}
					specialOption={`Before ${startYear}`}
					setYear={(year) => (record.Started = { Year: year, Month: -1 })}
					setSpecial={() => (record.Started = null)}
				/>
				{!!record.Started && record.Started.Year > 0 && (
					<MonthSelect
						month={record.Started.Month}
						setMonth={(month) => (record.Started = { Year: record.Started?.Year ?? -1, Month: month })}
						enableMonth={(month) =>
							(record.Started?.Year !== currentYear || month <= currentMonth) &&
							(!record.Finished || record.Finished.Year !== record.Started?.Year || record.Finished.Month < 0 || record.Finished.Month > month)
						}
					/>
				)}
			</label>
			<label class={inputGroupStyles}>
				<div class="input-group-prepend input-group-text">Finished</div>
				<YearSelect
					start={startYear}
					end={endYear}
					min={record.Started && record.Started.Year > 0 ? Math.max(startYear, record.Started.Year) : startYear}
					year={record.Finished?.Year ?? null}
					specialOption="I still work here"
					setYear={(year) => (record.Finished = { Year: year, Month: -1 })}
					setSpecial={() => (record.Finished = null)}
				/>
				{!!record.Finished && record.Finished.Year > 0 && (
					<MonthSelect
						month={record.Finished.Month}
						setMonth={(month) => (record.Finished = { Year: record.Finished?.Year ?? -1, Month: month })}
						enableMonth={(month) =>
							(record.Finished?.Year !== currentYear || month <= currentMonth) &&
							(!record.Started || record.Started.Year !== record.Finished?.Year || record.Started.Month < month)
						}
					/>
				)}
			</label>
		</div>
	)
}

export default defineComponent(EmploymentDates, {
	record: requiredProp(Object),
})
