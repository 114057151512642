import type { Nullable } from "@/types"
import { computed } from "vue"
import { defineComponent, optionalProp, requiredProp, useOnInputNumber, type ReactiveComponent } from "vue-utils"

interface Props {
	start: number
	end: number
	min?: number
	max?: number
	year: Nullable<number>
	specialOption: string
	setYear(year: number): void
	setSpecial(): void
}

const YearSelect: ReactiveComponent<Props> = (props) => {
	const years = computed(() => {
		const yearsArr: number[] = []
		for (let i = props.start; i <= props.end; i++) {
			yearsArr.push(i)
		}
		return yearsArr
	})
	const selectValue = computed(() => {
		if (props.year === null) {
			return -1
		} else if (props.year < 0) {
			return ""
		} else {
			return props.year
		}
	})

	function handleInput(value: number) {
		if (value < 0) {
			props.setSpecial()
		} else {
			props.setYear(value)
		}
	}

	function isYearEnabled(year: number) {
		if (props.min !== undefined && year < props.min) {
			return false
		}
		if (props.max !== undefined && year > props.max) {
			return false
		}
		return true
	}

	return () => (
		<select class="form-control" required value={selectValue.value} onInput={useOnInputNumber(handleInput)}>
			<option value="" disabled>
				Select Year
			</option>
			<option value={-1}>{props.specialOption}</option>
			{years.value.map((year) => (
				<option key={year} value={year} disabled={!isYearEnabled(year)}>
					{year}
				</option>
			))}
		</select>
	)
}

export default defineComponent(YearSelect, {
	start: requiredProp(Number),
	end: requiredProp(Number),
	min: optionalProp(Number),
	max: optionalProp(Number),
	year: requiredProp(Number, null),
	specialOption: requiredProp(String),
	setYear: requiredProp(Function),
	setSpecial: requiredProp(Function),
})
