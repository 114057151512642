/* eslint-disable @typescript-eslint/no-misused-promises */
/*POSTAL UNSUBSCRIBE LOGIC*/

import { httpJsonRequest, httpJsonRequestJsonResponse, RequestMethod } from "@/apis/httpService"
import { useLoading } from "@/composition/useLoading"
import type { CustomerClaimRecordForExport, Id } from "@/types"
import moment from "moment"
import { createApp, reactive } from "vue"
import { TaxRebateVuePlugin } from "../TaxRebateVuePlugin"
import { checkFields } from "../utils/validation"

/*DECISION FLOW LOGIC*/
const PostalUnsubscribe = reactive({
	Results: {},
	Search: {
		Surname: "",
		Postcode: "",
		ClaimRef: "",
	},
	SelectedStatusId: 0,
	Complete: false,
})

const { runAction } = useLoading()

//initialize vue context for Decision flow
function vueInitPostalUnsubscribe() {
	const app = createApp({
		name: "PostalUnsubscribe",
		data: () => ({ PostalUnsubscribe }),
		methods: {
			frontEndDateFormat(date: string) {
				return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY")
			},
		},
	})

	app.use(new TaxRebateVuePlugin())
	app.mount("#Unsubscribe")

	return app
}

export function postalUnsubscribeFormInit() {
	if ($("#Unsubscribe").length) {
		vueInitPostalUnsubscribe()
	}
}

async function lookupClaimsPostal(): Promise<CustomerClaimRecordForExport[]> {
	const search = {
		ClaimReference: PostalUnsubscribe.Search.ClaimRef,
		Surname: PostalUnsubscribe.Search.Surname,
		Postcode: PostalUnsubscribe.Search.Postcode,
		ClaimStatus: -1,
		FirstName: "",
		NINumber: "",
		BrandType: -1,
		EmailAddress: "",
		Brand: -1,
	}
	return await httpJsonRequestJsonResponse("/api/ui/claims/lookup/unsubscribe", search, RequestMethod.POST)
}

async function unsubscribeClaimsPostal(statusId: Id): Promise<void> {
	await httpJsonRequest(`/api/ui/claims/unsubscribewithresponse/${statusId}`, PostalUnsubscribe.Results, RequestMethod.POST)
}

$(document).on("click", "#btnUnsubLookup", async function (e) {
	e.preventDefault()
	const ok = await checkFields($(this))
	if (!ok) {
		return
	}

	PostalUnsubscribe.Results = await useLoading().runAction(lookupClaimsPostal())
})

$(document).on("click", "#btnUnsub", async (e) => {
	e.preventDefault()

	await runAction(unsubscribeClaimsPostal(PostalUnsubscribe.SelectedStatusId))
	PostalUnsubscribe.Complete = true
})
