import type { Id } from "@/types"
import { computed } from "vue"
import { defineComponent, optionalProp, requiredProp, useOnInputNumber, type ReactiveComponent } from "vue-utils"

interface Props {
	industryId?: Id
	occupationId: Id
	setOccupationId(id: Id): void
}

const OccupationSelect: ReactiveComponent<Props> = (props) => {
	const occupations = computed(() => {
		const occupations =
			props.industryId === undefined ? App.AllOccupations : App.AllOccupations.filter((occupation) => occupation.IndustryId === props.industryId)
		return [...occupations].sort((o1, o2) => o1.Name.localeCompare(o2.Name))
	})
	return () => (
		<select class="form-control" value={props.occupationId < 0 ? "" : props.occupationId} onInput={useOnInputNumber(props.setOccupationId)} required>
			<option value="" disabled>
				Select Job Type
			</option>
			{occupations.value.map((occupation) => (
				<option key={occupation.OccupationId} value={occupation.OccupationId}>
					{occupation.Name}
				</option>
			))}
		</select>
	)
}

export default defineComponent(OccupationSelect, {
	industryId: optionalProp(Number),
	occupationId: requiredProp(Number),
	setOccupationId: requiredProp(Function),
})
