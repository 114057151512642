import moment from "moment"
import { checkFields } from "../utils/validation"
import { createApp, reactive } from "vue"
import { TaxRebateVuePlugin } from "../TaxRebateVuePlugin"
import { defineGlobals, delay } from "vue-utils"
import type { ClaimType } from "@/steps"
import type { Id } from "@/types"
import { httpJsonRequest, httpRequest, httpRequestJsonResponse, RequestMethod } from "@/apis/httpService"
import { useLoading } from "@/composition/useLoading"

/*RESPONSE FORM LOGIC*/
const AppResponse = reactive({
	ClaimId: 0,
	ReminderId: "",
	ClaimResponseStatusId: 0,
	DateFormsSent: moment().format("DD/MM/YYYY"),
	Message: "",
	FormId: 0,
	ResponseComplete: false,
	SendNewForms: false,
	AddressChange: false,
	AddressChangeKey: "",
	Action: "",
})

const { runAction } = useLoading()

//initialize vue context for Response Form
function vueResponseInit() {
	const app = createApp({
		name: "ClaimResponse",
		data: () => ({ AppResponse }),
		watch: {
			async "AppResponse.Action"() {
				//AppResponse.Action =
				if (AppResponse.Action === "ResendForms") {
					AppResponse.AddressChange = true
					if (AppResponse.AddressChangeKey === "") {
						AppResponse.AddressChangeKey = await getAddressKey(AppResponse.ReminderId)
					}
				}
			},
		},
		methods: {
			frontEndDateFormat(date: string) {
				return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY")
			},
			setAction(action: string) {
				AppResponse.Action = action
			},
		},
	})

	app.use(new TaxRebateVuePlugin())
	app.mount("#ClaimResponse")

	return app
}

export function responseFormInit() {
	if ($("#ClaimResponse").length) {
		vueResponseInit()
		const formid = $("#ClaimResponse").data("formid")
		const claimid = $("#ClaimResponse").data("claimid")
		AppResponse.FormId = formid
		AppResponse.ClaimId = claimid
	}
}

async function saveClaimResponse(claimId: Id, claimResponseStatusId: Id, dateFormsSent: string, message: string, formId: Id): Promise<void> {
	const d = moment(dateFormsSent, "DD/MM/YYYY")
	const cleanDate = d.format("YYYY-MM-DD")
	const resendForms = AppResponse.SendNewForms

	const data = {
		ClaimId: claimId,
		ClaimResponseStatusId: claimResponseStatusId,
		DateFormsSent: cleanDate,
		Message: message,
		FormId: formId,
	}
	await httpJsonRequest(`/api/ui/claim/${claimId}/response/save?resendforms=${String(resendForms)}`, data, RequestMethod.POST)
}


// eslint-disable-next-line @typescript-eslint/no-misused-promises
$(document).on("click", "#ClaimResponse button.save", async function (e) {
	e.preventDefault()

	const ok = await checkFields($(this))
	if (!ok) {
		return
	}

	await runAction(
		saveClaimResponse(AppResponse.ClaimId, AppResponse.ClaimResponseStatusId, AppResponse.DateFormsSent, AppResponse.Message, AppResponse.FormId)
	)

	if (AppResponse.AddressChange) {
		window.location.href = `/claims/address/?id=${AppResponse.AddressChangeKey}`
	} else {
		if (AppResponse.Action === "Unsubscribe") {
			await runAction(unsubscribeClaim(AppResponse.ClaimId, "all"))
		}
		AppResponse.ResponseComplete = true
	}
})

async function getAddressKey(claimReminderId: string): Promise<string> {
	return await httpRequestJsonResponse(`/api/ui/claimreminder/${claimReminderId}/addresskey`, RequestMethod.GET)
}

async function unsubscribeClaim(claimId: Id, type: ClaimType | "all"): Promise<void> {
	await httpRequest(`/api/ui/claim/${claimId}/unsubscribe/${type}`, RequestMethod.GET)
}

defineGlobals({ AppResponse })
