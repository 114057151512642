import ButtonNavigation from "@/components/ButtonNavigation"
import { defineComponent, watchDebounce, type ReactiveComponent } from "vue-utils"
import PostcodeInput from "./components/PostcodeInput"
import ResumeClaim from "./components/ResumeClaim"
import createSearchSelect from "@/components/SearchSelect"
import type { EmploymentRecord, OccupationSearch } from "@/types"
import { occupationSearch } from "@/apis"
import { ref, toRef } from "vue"
import { toReactive } from "@vueuse/core"




const JobSelect = createSearchSelect<OccupationSearch>()


const PostcodeStep: ReactiveComponent = () => {


    App.CustomerClaim.EmploymentRecords[0] = App.CustomerClaim.EmploymentRecords[0] ?? {
        EmploymentId: 0,
        JobTitle: "",
        IndustryId: null,
        OccupationId: null,
        Uniformed: true,
        AddressLine1: "",
        EmployerName: "",
        PAYEReference: "",
        Postcode: "",
        Started: {
            Year: -1,
            Month: -1,
        },
        Finished: {
            Year: -1,
            Month: -1,
        },
    }

    const occupationSearchResults = ref<OccupationSearch[]>([])
    var noCurrentJob = ref<boolean>(false)

    async function runOccupationSearch(search: string) {
        const results = search.length === 0 ? [] : await occupationSearch(search)
        occupationSearchResults.value = results
    }
    watchDebounce(toRef(App.CustomerClaim.EmploymentRecords[0], "JobTitle"), () => void runOccupationSearch(App.CustomerClaim.EmploymentRecords[0].JobTitle))
    watchDebounce(noCurrentJob, () => {
        if (noCurrentJob.value) {
            const newData: Partial<EmploymentRecord> = {
                JobTitle: "",
                OccupationId: null,
                IndustryId: null,
            }
            Object.assign(App.CustomerClaim.EmploymentRecords[0], newData)
        }
    })

    function onSelectJob(job: OccupationSearch) {
        const newData: Partial<EmploymentRecord> = {
            IndustryId: job.IndustryId,
            OccupationId: job.OccupationId,
            JobTitle: job.Name,
        }
        Object.assign(App.CustomerClaim.EmploymentRecords[0], newData)
    }

    function updateJobSearch(search: string) {
        const newData: Partial<EmploymentRecord> = {
            JobTitle: search,
            OccupationId: null,
            IndustryId: null,
        }
        Object.assign(App.CustomerClaim.EmploymentRecords[0], newData)
    }

    return () => (
        <>
            <div class="col col-md-10 col-lg-8 mx-auto">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col mb-3 mx-auto text-center">Enter your <strong>HOME postcode</strong> and your <strong>most recent job title</strong> to find out what you're owed</div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <PostcodeInput
                                    class="text-center bold my-auto"
                                    postcode={App.CustomerClaim.Postcode}
                                    setPostcode={(postcode) => (App.CustomerClaim.Postcode = postcode)}
                                />
                            </div>
                            {!noCurrentJob.value &&
                                <>
                                <div class="col-12 col-md-6">
                                    <JobSelect
                                        class="text-center"
                                        style={{ flexGrow: 1 }}
                                        inputProps={{ class: "form-control text-center", placeholder: "Most recent job title", required: true }}
                                        getKey={(o) => o.Id.toString()}
                                        getText={(o) => o.Name}
                                        options={occupationSearchResults.value}
                                        searchTerm={App.CustomerClaim.EmploymentRecords[0].JobTitle}
                                        setSearch={updateJobSearch}
                                        setOption={onSelectJob}
                                    />
                                </div>
                                <div class="col-6"></div>
                                </>}
                            <div class="col-6 text-center">
                                <label class="custom-control custom-control-inline custom-checkbox">
                                    <input type="checkbox" v-model={noCurrentJob.value} value={true} name="noJob" class="custom-control-input" />
                                    <span class="custom-control-label">I don't currently have a job</span>
                                </label>
                            </div>
                        </div>

                    </div>
                    <div class="col-12 align-middle">
                        {App.IsQuickEstimate && App.InitialLoadWithExistingClaim ? <ResumeClaim /> : <ButtonNavigation hideBack />}
                    </div>
                </div>
            </div>
        </>
    )
}

export default defineComponent(PostcodeStep)
