import ButtonNavigation from "@/components/ButtonNavigation"
import { useCustomValidation } from "@/composition/useCustomValidation"
import { defineComponent, useOnInput, type ReactiveComponent } from "vue-utils"
import { goToNextFocusable, useNext } from "@/composition/useNext"


const NationalInsuranceStep: ReactiveComponent = () => {
	function niValue(index: number) {
		const ni = App.CustomerClaim.NI
		return ni.length <= index ? "" : App.CustomerClaim.NI.charAt(index)
	}
	function setNiValue(index: number, char: string) {
		const ni = App.CustomerClaim.NI.padEnd(index + 1, " ")
		App.CustomerClaim.NI = ni.substring(0, index) + char.toUpperCase() + ni.substring(index + 1)
	}
	function isCharacterInput(index: number) {
		return index === 0 || index === 1 || index === 8
	}
	function checkNIDigit(value: string, index: number) {
		return value.length > 0 && (isCharacterInput(index) ? /[a-z]/i.test(value) : /[0-9]/.test(value))
	}
	useCustomValidation(() => {
		//const niReg = /^[a-ceghj-pr-tw-zA-CEGHJ-PR-TW-Z]{1}[a-ceghj-pr-tw-zA-CEGHJ-NPR-TW-Z]{1}[0-9]{6}[a-dfmA-DFM]{0,1}$/
		// https://stackoverflow.com/questions/10204378/regular-expression-to-validate-uk-national-insurance-number
		const niReg = /^(?!BG|GB|NK|KN|TN|NT|ZZ)[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z](?:\s?\d){6}\s?[A-DM ]$/i
		const niResult = niReg.test(App.CustomerClaim.NI)
		return niResult ? true : "Please enter a valid NI number"
	})

	return () => (
		<>
			<div class="col-12 my-2 text-center">
				<h3 class="bold">Final Details</h3>
			</div>
			<div class="col-12 claim-label">
				In order to process your claim we will need your National Insurance Number and your phone number.
				<br />
			</div>
			<div class="col-12 text-center">
				<small class="bold">National Insurance Number (eg AA 123456 B)</small>
			</div>
			<div class="col-12 my-2 form-inline justify-content-center flex-nowrap">
				{Array.from({ length: 9 }, (_, i) => (
					<input
						key={i}
						type={isCharacterInput(i) ? "text" : "number"}
						value={niValue(i)}
						onInput={e => {
							const target = e.target as HTMLInputElement
							setNiValue(i, target.value)
							if (checkNIDigit(target.value, i)) {
								goToNextFocusable(target)
							}
						}}
						placeholder={isCharacterInput(i) ? "X" : "0"}
						maxlength={1}
						class="form-control fragment required"
						v-mask={isCharacterInput(i) ? "A" : "#"}
						data-validation="Please provide your National Insurance Number, eg AA123456B"
						data-validation-groupcontrolid="NI"
					/>
				))}
				<br />
				<input
					type="hidden"
					v-model={App.CustomerClaim.NI}
					class="d-none"
					id="inp_NI"
					placeholder="National Insurance Number"
					data-validation="Please provide your National Insurance Number, eg AA123456B"
				/>
			</div>
			<div class="col-12 my-2 text-center">
				{!App.CustomerClaim.noPhone ? (
					<input
						type="tel"
						v-model={App.CustomerClaim.Phone}
						class="form-control"
						id="inp_phone"
						v-mask="##### ######"
						placeholder="Mobile phone number"
						data-validation="Please provide your contact phone number"
					/>
				) : (
					<input type="tel" class="form-control" placeholder="Mobile phone number" disabled />
				)}
			</div>

			<ButtonNavigation />
		</>
	)
}

export default defineComponent(NationalInsuranceStep)
