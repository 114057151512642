import { createApp } from "vue"
import { TaxRebateVuePlugin } from "@/TaxRebateVuePlugin"

import { reactive } from "vue"
import type { ClaimCustomer, EnumEntry, MODClaim, OccupationSerializable, TaxYearViewModel, Brand } from "@/types"
import DisplayCurrentStep from "@/steps/DisplayCurrentStep"
import ClaimStep from "@/components/ClaimStep"
import ClaimSteps, { ClaimStepData, ClaimType } from "@/steps"
import CloseClaimButton from "@/components/CloseClaimButton"
import { setClaimStep } from "@/step-handler"
import initClaim from "@/initClaim"

export interface AppData {
    industrySectors: EnumEntry[]
    industrySectorsFiltered: EnumEntry[]
    occupationsFiltered: EnumEntry[]
    currentTaxYears: EnumEntry[]
    AllOccupations: OccupationSerializable[]
    MarriageTaxYears: TaxYearViewModel[] | null
    CustomerClaim: ClaimCustomer
    MODClaim: MODClaim
    Brand: Brand
    IsQuickEstimate: boolean
    InitialLoadWithExistingClaim: boolean
}

const App = reactive<AppData>({
    industrySectors: [],
    industrySectorsFiltered: [],
    occupationsFiltered: [],
    currentTaxYears: [],
    AllOccupations: [],
    MarriageTaxYears: null,
    CustomerClaim: undefined as unknown as ClaimCustomer,
    MODClaim: undefined as unknown as MODClaim,
    Brand: brand,
    IsQuickEstimate: true,
    InitialLoadWithExistingClaim: !!localStorage.getItem("CustomerClaim"),
})

globalThis.App = App

function isClaimIncomplete(currentStep: ClaimStepData): boolean {
    switch (currentStep.type) {
        case ClaimType.Standard:
            return currentStep.step > ClaimSteps.InitialStep.step && currentStep.step < ClaimSteps.FinalStep.step
        case ClaimType.MoD:
            return currentStep.step > ClaimSteps.InitialStep.step && currentStep.step < ClaimSteps.FinalMoDStep.step
    }
    throw new Error(`Unknown step type '${currentStep.type as string}'`)
}

export async function createTaxClaimApp() {
    const element = document.getElementById("ClaimWrapper")
    if (!element) {
        return
    }

    initClaim()
    await setClaimStep(CurrentStep, {
        initialLoad: true,
    })

    if (CurrentStep === ClaimSteps.ClaimComplete) $("#claim_complete").show()
    else $("#claim_complete").hide()

    if (isClaimIncomplete(CurrentStep)) {
        $("#resume_claim").show()
        $("#ClaimUSP").text("Complete your claim - you're nearly there!")
    } else {
        $("#resume_claim").hide()
        $("#ClaimUSP").text("Make a claim now - it only takes 2 minutes")
    }

    App.IsQuickEstimate = element.dataset.openDefault === "false" && !CurrentStep.isIssue
    App.InitialLoadWithExistingClaim = App.InitialLoadWithExistingClaim && !CurrentStep.isIssue

    const app = createApp({
        data: () => ({
            App,
        }),
        mounted() {
            App.CustomerClaim.SourceData = sourcedata
        },
        render() {
            return (
                <div id="Claim" class={App.IsQuickEstimate ? "QuickEstimate jumbotron" : ""}>
                    {!App.IsQuickEstimate && <CloseClaimButton />}
                    <div class="inner-claim-container py-2 py-sm-3 px-1 px-sm-4">
                        {App.IsQuickEstimate ? (
                            <h2>
                                {App.Brand.DomainName.includes("taxrefund") ? (
                                    <>
                                        <span class="xlite">Quick Estimate </span>
                                        <span class="xbold">- Find out how much you could get</span>
                                    </>
                                ) : (
                                    <a href="https://www.taxrefund.co.uk/?ub" target="_blank" style="color: #fff;">
                                        <span class="xbold">Due a tax rebate? </span>
                                        <span class="xlite"> Find out at <img src="/content/images/taxrefund-reverse.svg" style="width: 200px;" /></span>
                                    </a>
                                )}
                            </h2>
                        ) : (
                            <>
                                <h2 class="xbold d-none d-sm-block">Claim Your Tax Refund</h2>
                                <hr class="d-none d-sm-block" />
                            </>
                        )}

                        {App.IsQuickEstimate && App.InitialLoadWithExistingClaim && !CurrentStep.isIssue ? (
                            <ClaimStep step={ClaimSteps.InitialStep} />
                        ) : (
                            <DisplayCurrentStep />
                        )}
                    </div>
                </div>
            )
        },
    })

    app.use(new TaxRebateVuePlugin())
    app.mount(element)
}
