import { createMountedList } from "./mountedList"

export type CustomCheck = () => boolean | Promise<boolean>

const { use, registrations } = createMountedList<CustomCheck>()

export const useCustomCheck = use

export async function runCustomChecks(): Promise<boolean> {
	for (const check of registrations.values()) {
		if (!(await check())) {
			return false
		}
	}
	return true
}
