import ButtonNavigation from "@/components/ButtonNavigation"
import Question from "@/components/Question"
import RadioButton from "@/components/RadioButton"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const QualifyingStep: ReactiveComponent = () => {
	return () => (
		<>
			{App.CustomerClaim.Married && (
				<Question question="Have you ever claimed for marriage allowance transfer?">
					<RadioButton
						name="marriage-allowance-transfer"
						value={App.CustomerClaim.MarriageAllowanceTransfer}
						setValue={(value) => (App.CustomerClaim.MarriageAllowanceTransfer = value)}
						data-validation="Please tell us whether or not you have ever claimed for marriage allowance transfer"
						required
					/>
				</Question>
			)}

			<Question question="Have you claimed uniform allowance in your current job?">
				<RadioButton
					name="uniform-allowance"
					value={App.CustomerClaim.ClaimedUniformAllowanceCurrentJob}
					setValue={(value) => (App.CustomerClaim.ClaimedUniformAllowanceCurrentJob = value)}
					data-validation="Please tell us whether or not you have claimed uniform allowance in your current job"
					required
				/>
			</Question>

			<Question question="Have you claimed uniform allowance in a previous job you had in the last 4 years?">
				<RadioButton
					name="uniform-allowance-past"
					value={App.CustomerClaim.ClaimedUniformAllowancePreviousJob}
					setValue={(value) => (App.CustomerClaim.ClaimedUniformAllowancePreviousJob = value)}
					data-validation="Please tell us whether or not you claimed uniform allowance in a previous job you had in the last 4 years"
					required
				/>
			</Question>

			<ButtonNavigation />
		</>
	)
}

export default defineComponent(QualifyingStep)
