import { useInputValidation } from "@/composition/useInputValidation"
import { InputHTMLAttributes, ref, toRef, watch } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import { useNext } from "@/composition/useNext"

interface Props {
    date: Date | null | string
    setDate(date: string): void
}

const inputRegex = /^((0[1-9])|([1-2]\d)|(3[0-1]))\/((0[1-9])|10|11|12)\/((19|20)\d{2})$/

function formatDate(date: Date): string {
    if (!Number.isFinite(date.valueOf())) {
        return ""
    }
    return date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    })
}

function getDayStr(date: Props["date"]): string {
    return formatDateInputString(date).split('/')[0]
}
function getMonthStr(date: Props["date"]): string {
    return formatDateInputString(date).split('/')[1]
}
function getYearStr(date: Props["date"]): string {
    return formatDateInputString(date).split('/')[2]
}

function formatDateInputString(date: Props["date"]): string {
    if (!date) {
        return ""
    }
    if (date instanceof Date) {
        return formatDate(date)
    }
    const parsedDate = new Date(date)
    if (!Number.isFinite(parsedDate.valueOf())) {
        return date
    }
    return formatDate(parsedDate)
}

function tryParseDate(str: string): Date | null {
    if (!inputRegex.test(str)) {
        return null
    }
    const parts = str.split("/").map((part) => Number.parseInt(part))
    const date = new Date(parts[2], parts[1] - 1, parts[0])
    if (date.getDate() !== parts[0] || date.getMonth() !== parts[1] - 1 || date.getFullYear() !== parts[2]) {
        return null
    }
    return date
}

const SplitDateInput: ReactiveComponent<Props, Omit<InputHTMLAttributes, "value">> = (props, { attrs }) => {
    const inputRef = ref<HTMLInputElement>()

    const dayStr = ref(getDayStr(props.date))
    const monthStr = ref(getMonthStr(props.date))
    const yearStr = ref(getYearStr(props.date))
    const inputs = Array.prototype.slice.call(
        document.querySelectorAll("input")
    )

    var dateStr = `${dayStr.value}/${monthStr.value}/${yearStr.value}`
    watch(toRef(props, "date"), (date) => {
        dateStr = formatDateInputString(date)
    })

    watch([dayStr, monthStr, yearStr], () => {

        if (dayStr && monthStr && yearStr && yearStr.value && yearStr.value.length == 4) {
            const s = `${dayStr.value}/${monthStr.value}/${yearStr.value}`
            var date = tryParseDate(s)
            if (date) {
                const offset = date.getTimezoneOffset()
                date = new Date(date.getTime() - (offset * 60 * 1000))
                props.setDate(date.toISOString().split('T')[0])
            }
            else {
                props.setDate('')
            }
        }
    })

    useInputValidation(inputRef, () => {
        if (dateStr.length > 0 && !tryParseDate(dateStr)) {
            return "Please enter a valid date"
        }
        return true
    })

    function checkTwoDigits(value: string) {
        return /[0-9]{2}/.test(value)
    }

    return () =>
        <div class="input-group col-12" {...attrs} >
            <div class="input-group-prepend">
                <span class="input-group-text">Date of birth</span>
            </div>
            <input ref={inputRef} onInput={useNext(checkTwoDigits)} class="form-control text-center" placeholder="day" type="text" v-mask="##" v-model={dayStr.value} />
            <input ref={inputRef} onInput={useNext(checkTwoDigits)} class="form-control text-center" placeholder="month" type="text" v-mask="##" v-model={monthStr.value} />
            <input ref={inputRef} class="form-control text-center" placeholder="year" type="text" v-mask="####" v-model={yearStr.value} />
        </div>

}

export default defineComponent(SplitDateInput, {
    date: requiredProp(Date, String, null),
    setDate: requiredProp(Function),
})
