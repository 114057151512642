import { onMounted, ref } from "vue"
import { anyProp, defineComponent, optionalProp, type ReactiveComponent } from "vue-utils"
import type { ModalData } from "./types"

const BootstrapModal: ReactiveComponent<ModalData> = (props) => {
	const modalRef = ref<HTMLElement>()

	onMounted(open)

	function open() {
		const el = modalRef.value
		if (el) {
			$(el).modal("show")
			$(el).on("hidden.bs.modal", () => props.onClosed?.())
		}
	}
	function close() {
		const el = modalRef.value
		if (el) {
			$(el).modal("hide")
		}
	}

	function renderTitle() {
		if (typeof props.title === "string") {
			return props.title
		}
		return <props.title close={close} />
	}

	function renderContent() {
		if (typeof props.content === "string") {
			return props.content
		}
		return <props.content close={close} />
	}

	function renderFooter() {
		if (!props.footer) {
			return (
				<button type="button" class="btn btn-secondary" onClick={close}>
					Close
				</button>
			)
		}
		if (typeof props.footer === "string") {
			return props.footer
		}
		return <props.footer close={close} />
	}

	return () => (
		<div ref={modalRef} class="modal fade">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">{renderTitle()}</h5>
						{props.closeButton !== false && (
							<button type="button" class="close" aria-label="Close" onClick={close}>
								<span aria-hidden="true">&times;</span>
							</button>
						)}
					</div>
					<div class="modal-body">{renderContent()}</div>
					<div class="modal-footer">{renderFooter()}</div>
				</div>
			</div>
		</div>
	)
}

export default defineComponent(BootstrapModal, {
	closeOnBackgroundClick: optionalProp(Boolean),
	closeButton: optionalProp(Boolean),
	title: anyProp(),
	content: anyProp(),
	footer: anyProp(),
	onClosed: optionalProp(Function),
})
