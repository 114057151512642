import CurrencyInput from "@/components/CurrencyInput"
import RadioButton from "@/components/RadioButton"
import { selectableEmploymentYears, pandemicYearIds } from "@/constants"
import type { EmploymentRecord, Id, Subscription } from "@/types"
import { computed } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import TextualDateInput from "./TextualDateInput"

function formatJobStartDate(started: EmploymentRecord["Started"]) {
	const startYear = new Date().getFullYear() - (selectableEmploymentYears - 1)
	return started ? `${String(started.Month).padStart(2, "0")}/${String(started.Year)}` : `Before ${startYear}`
}

function formatJobFinishDate(finished: EmploymentRecord["Finished"]) {
	return finished ? `${String(finished.Month).padStart(2, "0")}/${String(finished.Year)}` : "I still work here"
}

function taxYearName(id: Id) {
	return App.currentTaxYears.find((ty) => ty.Value === id)?.Key ?? ""
}
function getSubAmount(sub: Subscription, ty: Id) {
	return sub.Years.find((y) => y.TaxYearId === ty)?.Amount ?? 0
}

const ReviewDetails: ReactiveComponent = () => {
	const enabledSubscriptions = computed(() => App.CustomerClaim.Subscriptions.filter((sub) => sub.Enabled))

	return () => (
		<>
			<section>
				<h3>Basic Details</h3>
				<div class="section-columns">
					<input
						class="form-control"
						v-model={App.CustomerClaim.Title}
						placeholder="Title"
						style="grid-column-start: span 2"
						readonly
						required
					/>
					<input class="form-control" v-model={App.CustomerClaim.FirstName} placeholder="Your first name(s)" readonly required />
					<input class="form-control" v-model={App.CustomerClaim.Surname} placeholder="Your surname" readonly required />
					<input class="form-control match" v-model={App.CustomerClaim.Email} placeholder="Your email address" readonly required />
					<input class="form-control match" v-model={App.CustomerClaim.emailConfirm} placeholder="Repeat your email address" readonly required />
				</div>
				<hr class="w-100" />
			</section>

			<section>
				<h3>Address and Job</h3>
				<div class="section-columns">
					<input
						type="text"
						class="form-control"
						id="inp_address1_v"
						value={App.CustomerClaim.Address1}
						placeholder="Address line 1"
						readonly
						required
					/>
					<input
						type="text"
						class="form-control"
						id="inp_address2_v"
						value={App.CustomerClaim.Address2}
						placeholder="Address line 2"
						readonly
						required
					/>
					<input
						type="text"
						class="form-control"
						id="inp_address3_v"
						v-model={App.CustomerClaim.Address3}
						placeholder="Address line 3"
						readonly
					/>
					<input type="text" class="form-control" id="inp_county_v" v-model={App.CustomerClaim.County} placeholder="County" readonly required />

					<TextualDateInput
						class="form-control"
						date={App.CustomerClaim.DateOfBirth}
						setDate={(date) => (App.CustomerClaim.DateOfBirth = date)}
						placeholder="Your date of birth"
						readonly
						required
					/>
				</div>
				<hr class="w-100" />
			</section>

			<section>
				<h3>Marriage</h3>

				<RadioButton
					name="married"
					value={App.CustomerClaim.Married}
					setValue={(v) => (App.CustomerClaim.Married = v)}
					legend="Are you currently married or in a civil partnership?"
					readonly
					required
				/>

				<hr class="w-100" />
			</section>

			<section>
				<h3>National Insurance Number and Telephone</h3>
				<div class="section-columns">
					<input
						class="form-control"
						value={App.CustomerClaim.NI}
						v-mask="AA ###### A"
						minlength={11}
						maxlength={11}
						pattern="[A-Z]{3}[0-9]{6}[A-Z]"
						placeholder="NI Number (e.g. AB 123456 B)"
						required
						readonly
					/>
					{!App.CustomerClaim.noPhone && (
						<input
							type="tel"
							v-model={App.CustomerClaim.Phone}
							class="form-control"
							id="inp_phone_v"
							v-mask="##### ######"
							placeholder="Mobile phone number"
							readonly
							required
						/>
					)}
				</div>
				<hr class="w-100" />
			</section>

			<section style="break-before: page">
				<h3>Employment records</h3>

				<ol class="d-flex flex-column align-items-center">
					{App.CustomerClaim.EmploymentRecords.map((employmentRecord, i) => (
						<li key={i} class="mb-3">
							<h5 style="font-size: 1rem; font-weight: bold">
								{employmentRecord.JobTitle} at {employmentRecord.EmployerName}
							</h5>
							<div class="d-flex" style="column-gap: 1rem">
								<small>Started: {formatJobStartDate(employmentRecord.Started)}</small>
								<small>Finished: {formatJobFinishDate(employmentRecord.Finished)}</small>
							</div>
							<div class="d-flex">
								<small>Wore Uniform: {employmentRecord.Uniformed ? "Yes" : "No"}</small>
							</div>
						</li>
					))}
				</ol>

				{App.CustomerClaim.ConsentContactEmployers !== null && (
					<RadioButton
						name="contact-employers"
						legend="Do you consent to One Time Refund Ltd contacting your previous employers to obtain their PAYE Refs?"
						value={App.CustomerClaim.ConsentContactEmployers}
						setValue={() => void 0}
						required
						disabled
					/>
				)}

				<hr class="w-100" />
			</section>

			<section>
				<h3>Working from home</h3>
				<p>These are the years in which you have indicated that you worked from home:</p>

				<table class="grid-table w-100" style="grid-template-columns: 3fr repeat(2, minmax(4rem, 1fr)) minmax(8rem, 1fr); grid-auto-rows: 1fr">
					<thead style="display: contents">
						<tr style="display: contents">
							<th aria-label="Tax Year">Year started</th>
							<th>Yes</th>
							<th>No</th>
							<th class="text-center">Weeks</th>
						</tr>
					</thead>
					<tbody style="display: contents">
						{!!App.CustomerClaim.WorkFromHomeYears &&
							App.CustomerClaim.WorkFromHomeYears.length > 0 &&
							App.CustomerClaim.WorkFromHomeYears.map((taxyear) => (
								<tr key={taxyear.Id} style="display: contents !important;">
									<td>{`06/04/${taxYearName(taxyear.Id).split("/")[0].substr(2, 2)}`}</td>
									<td>
										<label class="custom-control custom-control-inline custom-radio" style={{ paddingLeft: "0.5rem" }}>
											<input
												type="radio"
												class="custom-control-input required"
												name={`taxyearWFHRO${taxyear.Id}`}
												value={true}
												v-model={taxyear.Value}
												disabled
												required
											/>
											<span class="custom-control-label ml-3"></span>
										</label>
									</td>
									<td>
										<label class="custom-control custom-control-inline custom-radio" style={{ paddingLeft: "0.5rem" }}>
											<input
												type="radio"
												class="custom-control-input required"
												name={`taxyearWFHRO${taxyear.Id}`}
												value={false}
												v-model={taxyear.Value}
												disabled
												required
											/>
											<span class="custom-control-label ml-3"></span>
										</label>
									</td>
									<td>
										{taxyear.Value === true && !pandemicYearIds.includes(taxyear.Id) && (
											<input
												type="number"
												class="form-control required"
												name="WeeksFromHome"
												data-min-val="1"
												data-max-val="52"
												v-model={taxyear.Weeks}
												min={1}
												max={52}
												required
												readonly
											/>
										)}
									</td>
								</tr>
							))}
					</tbody>
				</table>
				<hr class="w-100" />
			</section>

			{enabledSubscriptions.value.length > 0 && (
				<section>
					<h3>Subscriptions</h3>
					{enabledSubscriptions.value.map((sub) => (
						<div key={sub.OccupationSubscriptionId} class="row">
							<div class="col-12">
								<small>{sub.Name}</small>
							</div>
							{App.CustomerClaim.TaxYearIds.map((ty) => (
								<div key={ty} class="col-6 col-sm py-1 pr-0">
									<small class="d-block small">{taxYearName(ty)}</small>
									<CurrencyInput
										value={getSubAmount(sub, ty)}
										setValue={() => void 0}
										class="form-control form-control-sm required"
										style="max-width:95%;"
										disabled
										required
									/>
								</div>
							))}
						</div>
					))}
					<hr class="w-100" />
				</section>
			)}

			<section>
				<h3>Tax Years</h3>
				<ul class="d-flex flex-wrap w-100 justify-content-center" style="column-gap: 5rem; row-gap: 1rem; list-style: none">
					{App.CustomerClaim.TaxYearIds.map((ty) => (
						<li key={ty}>
							<small class="d-block">{taxYearName(ty)}</small>
						</li>
					))}
				</ul>
				<hr class="w-100" />
			</section>
		</>
	)
}

export default defineComponent(ReviewDetails)
