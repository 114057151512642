export function useNext(checkIsValid: (inputValue: string) => boolean): (e: Event) => void {
    return e => {
        const val = (e.target as HTMLInputElement).value
        if (checkIsValid(val)) {
            goToNextFocusable(e.target as Element)
        }
    }
}

export function goToNextFocusable(root: Element) {
    let currentElement = root
    while (true) {
        const next = currentElement.nextElementSibling
        if (!next) return
        if (next instanceof HTMLInputElement || next instanceof HTMLSelectElement) {
            if (!next.disabled) {
                next.focus()
                return
            }
        }
        for (const nested of next.querySelectorAll("input select")) {
            const element = nested as HTMLInputElement | HTMLSelectElement
            if (!element.disabled) {
                element.focus()
                return
            }
        }

        currentElement = next
    }
}