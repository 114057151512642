/* eslint-disable jsx-a11y/label-has-associated-control */
import { getEmployerReferences } from "@/apis"
import createSearchSelect from "@/components/SearchSelect"
import type { EmployerReference, EmploymentRecord } from "@/types"
import { toReactive } from "@vueuse/core"
import { ref, toRef, watch } from "vue"
import { defineComponent, requiredProp, watchDebounce, type ReactiveComponent } from "vue-utils"
import employment from "."
import DontKnowField from "./components/DontKnowField"
import { inputGroupStyles } from "./styles"

interface Props {
	record: EmploymentRecord
	highlightPAYE: boolean
}

const EmployerSelect = createSearchSelect<EmployerReference>()


const EmployerDetails: ReactiveComponent<Props> = (props) => {
	const record = toReactive(toRef(props, "record"))
	const employerSearchResults = ref<EmployerReference[]>([])
	var showPAYE: boolean = record.PAYEReference === null || record.PAYEReference == ""

	function onSelectEmployer(employer: EmployerReference) {
		record.EmployerName = employer.EmployerName
		record.PAYEReference = employer.EmployerRef
		showPAYE = false
	}

	async function runEmployerSearch(search: string) {
		const results = search.length === 0 ? [] : await getEmployerReferences(search)
		employerSearchResults.value = results
	}
	watchDebounce(toRef(record, "EmployerName"), () => void runEmployerSearch(record.EmployerName))

	watch(toRef(record, "PAYEReference"), (ref) => {
		if (ref === null) {
			record.AddressLine1 = ""
			record.Postcode = ""
		} else {
			record.AddressLine1 = null
			record.Postcode = null
		}
	})

	return () => (
		<section>
			<h5>Employer Details</h5>
			<p>
				If you can't find exactly the right employer from the list, please type the <strong>whole name</strong> of your employer <i>exactly</i>{" "}
				as it appears on your payslip - (e.g. <strong>Tesco Stores</strong>)
			</p>
			<label class={inputGroupStyles}>
				<div class="input-group-prepend input-group-text">Employer</div>
				<EmployerSelect
					style={{ flexGrow: 1 }}
					inputProps={{ class: "form-control", placeholder: "Search for your employer", required: true }}
					getKey={(o) => o.Id.toString()}
					getText={(o) => o.EmployerName}
					options={employerSearchResults.value}
					searchTerm={record.EmployerName}
					setSearch={(s) => (record.EmployerName = s)}
					setOption={onSelectEmployer}
				/>
			</label>
			{showPAYE &&
				<DontKnowField
					name="PAYE Ref"
					placeholder="Your employer's PAYE ref"
					highlightLabel={props.highlightPAYE}
					dontKnowDisabled={props.highlightPAYE}
					value={record.PAYEReference}
					setValue={(value) => (record.PAYEReference = value)}
				/>
			}
			{record.PAYEReference === null && (
				<>
					<DontKnowField
						name="Address"
						highlightLabel={props.highlightPAYE}
						placeholder="Your employer's street address"
						dontKnowDisabled={props.highlightPAYE}
						value={record.AddressLine1}
						setValue={(value) => {
							record.AddressLine1 = value
							record.Postcode = value === null ? null : record.Postcode ?? ""
						}}
					/>
					<DontKnowField
						name="Postcode"
						highlightLabel={props.highlightPAYE}
						placeholder="Your employer's postcode"
						dontKnowDisabled={props.highlightPAYE}
						value={record.Postcode}
						setValue={(value) => {
							record.Postcode = value
							record.AddressLine1 = value === null ? null : record.AddressLine1 ?? ""
						}}
					/>
				</>
			)}
		</section>
	)
}

export default defineComponent(EmployerDetails, {
	record: requiredProp(Object),
	highlightPAYE: requiredProp(Boolean)
})
